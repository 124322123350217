import { useEffect, useState } from 'react'

import { cx } from 'class-variance-authority'
import {
  add,
  format,
  isAfter,
  isBefore,
  isEqual,
  isWithinInterval
} from 'date-fns'

import { selectSelectedHallWithInfo } from '@/entities/halls'
import {
  generateNewIntervals,
  selectAllIntervals,
  selectCurDayInterval,
  selectCurDayIntervalIdx,
  setCurDayIntervalIdx
} from '@/entities/reservation-time-picker'
import { formatDateTimeFromScheduleDate, nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Label } from '@/shared/ui'

import css from './TimePicker.module.css'

type TimePickerProps = {
  workingHoursStart: Date
  workingHoursEnd: Date
  reservationDate?: Date
  reservationStart?: Date
  selectTimeHandler: (value: Date) => void
}

export function TimePicker({
  workingHoursStart,
  workingHoursEnd,
  reservationDate,
  reservationStart,
  selectTimeHandler
}: TimePickerProps) {
  const [isNowActive, setIsNowActive] = useState(false)
  const dispatch = useAppDispatch()
  const curDayIntervalIdx = useAppSelector(selectCurDayIntervalIdx)
  const curDayInterval = useAppSelector(selectCurDayInterval)
  const allIntervals = useAppSelector(selectAllIntervals)
  const hall = useAppSelector(selectSelectedHallWithInfo)
  const now = nowWithTimezone()

  const dateTimeFromSchedule =
    reservationDate &&
    hall &&
    formatDateTimeFromScheduleDate(reservationDate, hall.schedule, true)

  useEffect(() => {
    if (reservationDate) dispatch(generateNewIntervals(reservationDate))
  }, [reservationDate])

  const dayIntervalAddHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx + 1))
  }

  const dayIntervalSubHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx - 1))
  }

  return (
    <div className={css.picker}>
      <div className={css.picker__header}>
        <Label className={css.picker__title}>Время старта</Label>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalSubHandler}
          disabled={curDayIntervalIdx === 0}
        >
          <Icon name="arrowLeft" size={20} />
        </Button>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalAddHandler}
          disabled={curDayIntervalIdx === allIntervals.length - 1}
        >
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
      <div className={css.picker__options}>
        {curDayInterval.hours.map((hour) => {
          const isDisabled =
            isBefore(hour, now) ||
            isBefore(hour, workingHoursStart) ||
            (isAfter(hour, workingHoursEnd) &&
              isBefore(hour, add(workingHoursStart, { days: 1 })))

          const isActive = reservationStart && isEqual(hour, reservationStart)

          const endDateSchedule =
            dateTimeFromSchedule?.start_date !== undefined &&
            dateTimeFromSchedule?.end_date !== undefined &&
            (isBefore(
              new Date(dateTimeFromSchedule.end_date),
              new Date(dateTimeFromSchedule.start_date)
            )
              ? add(new Date(dateTimeFromSchedule.end_date), { days: 1 })
              : new Date(dateTimeFromSchedule.end_date))

          return (
            <Button
              key={hour.toISOString()}
              className={cx(css.picker__button, {
                [css.picker__button_active]: isActive && !isNowActive
              })}
              variant="tertiary"
              onClick={() => {
                selectTimeHandler(hour)
                setIsNowActive(false)
              }}
              disabled={
                isDisabled ||
                !(
                  dateTimeFromSchedule?.start_date !== undefined &&
                  endDateSchedule &&
                  isWithinInterval(hour, {
                    start: new Date(dateTimeFromSchedule.start_date),
                    end: endDateSchedule
                  })
                )
              }
            >
              {format(hour, 'HH:mm')}
            </Button>
          )
        })}
        <Button
          className={cx(css.picker__button, css.picker__button_now, {
            [css.picker__button_active]: isNowActive
          })}
          variant="tertiary"
          onClick={() => {
            selectTimeHandler(nowWithTimezone())
            setIsNowActive(true)
          }}
        >
          Сейчас
        </Button>
      </div>
    </div>
  )
}
