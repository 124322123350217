import { format, getHours, getMinutes, parse } from 'date-fns'

import { formatTimeForRequest } from './formatTimeForRequest'
import { nowWithTimezone } from './nowWithTimezone'

export const formatDateTimeFromScheduleDate = (
  curDate: Date,
  schedule: Schedule,
  withoutFormatForRequest?: boolean
): { start_date: string | undefined; end_date: string | undefined } => {
  const now = nowWithTimezone()
  const curWeekday = format(curDate, 'EEEE').toLowerCase() as WeekDays

  const startTime = schedule[curWeekday].start
  const endTime = schedule[curWeekday].end

  let startDate
  let endDate

  if (startTime) {
    const startTimeDate = parse(startTime, 'HH:mm', now)
    startDate = new Date(curDate)
    startDate.setHours(getHours(startTimeDate))
    startDate.setMinutes(getMinutes(startTimeDate))
  }

  if (endTime) {
    const endTimeDate = parse(endTime, 'HH:mm', now)
    endDate = new Date(curDate)
    endDate.setHours(getHours(endTimeDate))
    endDate.setMinutes(getMinutes(endTimeDate))
  }

  return {
    start_date: withoutFormatForRequest
      ? startDate?.toString()
      : formatTimeForRequest(startDate),
    end_date: withoutFormatForRequest
      ? endDate?.toString()
      : formatTimeForRequest(endDate)
  }
}
