import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { INFINITE_SCROLL_RES } from '@/app/config'
import { useReservationsQuery } from '@/entities/reservation'
import { useReservationRequestsWsQuery } from '@/entities/reservation-request'
import {
  ReservationSortEnum,
  ReservationStatusEnum,
  selectReservationsSearch,
  selectReservationsSelectedInterval,
  selectReservationsSortType,
  setReservationsSortType
} from '@/entities/reservations'
import {
  selectReservationsFilters,
  selectReservationsSortDate,
  selectReservationsSortTable
} from '@/entities/reservations/model/selectors'
import { selectSelectedAddressId } from '@/entities/session'
import { useDebounce } from '@/shared/hooks'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Icon, Tab, TabsGroup, TabsProvider } from '@/shared/ui'

import css from './MobileReservationsHeader.module.css'

type ReservationTotalCounts = {
  reservation_count: number
  total_persons?: number
}

const tabs = [
  { id: ReservationSortEnum.closest, label: 'Предстоящие' },
  { id: ReservationSortEnum.inProgress, label: 'Текущие' },
  { id: ReservationSortEnum.default, label: 'Все' },
  { id: ReservationSortEnum.requests, label: 'Заявки' }
]

export default function MobileReservationsHeader() {
  const [counts, setCounts] = useState<ReservationTotalCounts[]>([])
  const address = useAppSelector(selectSelectedAddressId)
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const search = useAppSelector(selectReservationsSearch)
  const sortType = useAppSelector(selectReservationsSortType)
  const selectSortDate = useAppSelector(selectReservationsSortDate)
  const selectSortTable = useAppSelector(selectReservationsSortTable)
  const selectFilters = useAppSelector(selectReservationsFilters)
  const dispatch = useAppDispatch()

  const debouncedSearch = useDebounce(search)

  const reservationsBasicParams = address
    ? {
        address,
        limit: INFINITE_SCROLL_RES,
        offset: 1 * INFINITE_SCROLL_RES,
        search: debouncedSearch,
        start_date: formatTimeForRequest(selectedInterval.start_date),
        end_date: formatTimeForRequest(selectedInterval.end_date),
        ordering: selectSortDate,
        ordering_places: selectSortTable,
        filters: selectFilters
      }
    : undefined

  const { data: dataConfirmed } = useReservationsQuery(
    reservationsBasicParams
      ? {
          ...reservationsBasicParams,
          status: [ReservationStatusEnum.confirmed]
        }
      : skipToken
  )
  const { data: dataInProgress } = useReservationsQuery(
    reservationsBasicParams
      ? {
          ...reservationsBasicParams,
          status: [ReservationStatusEnum.inProgress]
        }
      : skipToken
  )
  const { data: dataAll } = useReservationsQuery(
    reservationsBasicParams ?? skipToken
  )
  const { data: dataRequests } = useReservationRequestsWsQuery(
    address ? { addressId: address } : skipToken
  )

  useEffect(() => {
    if (dataConfirmed && dataInProgress && dataAll && dataRequests) {
      setCounts([
        {
          reservation_count: dataConfirmed.count,
          total_persons: dataConfirmed.total_persons
        },
        {
          reservation_count: dataInProgress.count,
          total_persons: dataInProgress.total_persons
        },
        { reservation_count: dataAll.count },
        { reservation_count: dataRequests?.[0]?.length ?? 0 }
      ])
    }
  }, [dataConfirmed, dataInProgress, dataAll, dataRequests])

  const valueChangeHandler = (value: string) => {
    dispatch(setReservationsSortType(value as ReservationSortEnum))
  }

  return (
    <TabsProvider
      value={sortType}
      className={css.provider}
      onValueChange={valueChangeHandler}
    >
      <TabsGroup className={css.list} variant="borderless">
        {tabs.map((tab, index) => (
          <Tab
            key={tab.id}
            variant="transparent"
            className={css.tab}
            value={tab.id.toString()}
          >
            {counts.length !== 0
              ? `${tab.label} (${counts[index].reservation_count})`
              : tab.label}
            {counts.length !== 0 &&
              Number.isInteger(counts[index].total_persons) && (
                <div className={css.tab__persons}>
                  <Icon name="users" size={12} />
                  <p>{counts[index]?.total_persons}</p>
                </div>
              )}
          </Tab>
        ))}
      </TabsGroup>
    </TabsProvider>
  )
}
